<template>
  <div class="page">
    <div class="wrap">
      <div class="tip">
        <a-icon class="icon" type="exclamation-circle" theme="filled" />
        {{ sysName }}只支持谷歌浏览器访问，请用谷歌浏览器进行注册、登录操作。
        <a
          href="https://www.google.cn/chrome/thank-you.html?statcb=1&installdataindex=empty&defaultbrowser=0#"
          target="_blank"
          style="padding-left: 24px"
          >浏览器下载</a
        >
      </div>
      <div class="title">
        <!-- <img class="logo" :src="require('@/assets/home/logo.png')" alt="" /> -->
        <img class="logo" :src="`/${sysLogo}`" alt="" />
        <div class="name">{{ sysName }}</div>
      </div>
      <div class="enter">
        <div
          class="card"
          :class="
            item.enterType == 1 ? 'small' : item.enterType == 2 ? 'middle' : ''
          "
          v-for="(item, index) in enterList"
          :key="index"
        >
          <div class="head">
            <div class="stage">{{ item.stageName }}</div>
            <div class="time">
              报名时间：{{ item.startTime | dateFilter }} ~
              {{ item.endTime | dateFilter }}
            </div>
          </div>
          <div class="notice">
            <div class="type">
              <div>【报名须知】</div>
              <div class="more" @click="toMore(1, item.enterType)">
                更多<a-icon
                  type="right"
                  style="font-size: 10px; margin: 0px 0 0 2px"
                />
              </div>
            </div>
            <div
              class="list"
              v-if="smallNoticeList.length > 0 && item.enterType == 1"
            >
              <div
                class="item"
                v-for="(item, index) in smallNoticeList"
                :key="index"
                @click="toDetail(item.id, 1)"
              >
                <div class="dot"></div>
                <div class="tit">{{ item.nTitle }}</div>
              </div>
            </div>
            <div
              class="list"
              v-if="middleNoticeList.length > 0 && item.enterType == 2"
            >
              <div
                class="item"
                v-for="(item, index) in middleNoticeList"
                :key="index"
                @click="toDetail(item.id, 1)"
              >
                <div class="dot"></div>
                <div class="tit">{{ item.nTitle }}</div>
              </div>
            </div>
            <div
              class="list"
              v-if="
                (smallNoticeList.length == 0 && item.enterType == 1) ||
                (middleNoticeList.length == 0 && item.enterType == 2)
              "
              style="text-align: center"
            >
              暂未发布
            </div>
            <div class="btn" @click="toLogin(item)">点击进入</div>
            <div class="process">
              <a href="javascript:;" @click="toImg(item)"
                >一图看懂{{ item.stageName }}流程</a
              >
            </div>
          </div>
        </div>
        <div class="card ewm">
          <div class="head">
            <div class="stage">微信端报名</div>
            <div class="time">WeChat</div>
          </div>
          <div class="info">
            <div class="type">【微信扫码】</div>
            <div class="ewmBg">
              <img class="img" :src="require('@/assets/ewm.png')" alt="" />
            </div>
            <div class="desc">简单易用 无需安装</div>
          </div>
        </div>
      </div>
      <div class="news">
        <div class="type">
          <div>相关政策</div>
          <div class="more" @click="toMore(2, 0)">
            更多<a-icon
              type="right"
              style="font-size: 10px; margin: 0px 0 0 2px"
            />
          </div>
        </div>
        <div v-if="noticeList.length > 0">
          <a-carousel autoplay>
            <div
              class="title"
              v-for="(item, index) in noticeList"
              :key="index"
              @click="toDetail(item.id, 2)"
            >
              {{ item.nTitle }}
            </div>
          </a-carousel>
        </div>
        <div v-else style="text-align: center">暂未发布</div>
      </div>

      <!-- 图片预览 -->
      <a-modal
        :width="800"
        :visible="previewVisible"
        :footer="null"
        @cancel="imgHandleCancel"
      >
        <img alt="example" style="width: 100%" :src="`/${imgUrl}`" />
      </a-modal>
      <!-- 入口未开启提醒 -->
      <a-modal
        v-model="showTip"
        title="温馨提示"
        :footer="null"
        :width="480"
        :closable="false"
        :centered="true"
        :maskClosable="false"
      >
        <div class="resultWrap">
          <a-icon class="icon" type="info-circle" theme="filled" />
          <div class="desc">{{ tipCon }}</div>
          <a-button
            type="primary"
            shape="round"
            size="large"
            block
            class="result-btn btn"
            @click="showTip = false"
          >
            确定
          </a-button>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getStage } from "@/api/api";
import { getNoticeList } from "@/api/notice";
import { getFlag } from "@/api/common";

export default {
  name: "Enter",
  data() {
    return {
      year: new Date().getFullYear(),
      schoolCode: "1111111111", // 学校编码
      smallNoticeList: [], // 小学须知
      middleNoticeList: [], // 初中须知
      noticeList: [], // 相关政策
      enterList: [],
      showTip: false,
      tipCon: "",
      previewVisible: false, // 图片预览
      imgUrl: "",
      sysName: "",
      sysLogo: "",
    };
  },
  filters: {
    dateFilter(val) {
      return moment(+val).format("YYYY-MM-DD");
    },
  },
  methods: {
    toImg(item) {
      console.log(item, "00,");
      if (item.imgUrl) {
        this.imgUrl = item.imgUrl;
        this.previewVisible = true;
      } else {
        this.$message.error("管理员暂未设置入学流程图");
      }
    },
    imgHandleCancel() {
      this.previewVisible = false;
    },
    getEnter() {
      getStage({
        schoolCode: this.schoolCode,
      }).then((res) => {
        this.enterList = res.data;
      });
    },
    toMore(type, xdType) {
      this.$router.push({
        path: "/notice2",
        query: {
          type: type,
          xdType: xdType,
        },
      });
    },
    // 详情
    toDetail(id, type) {
      this.$router.push({
        path: "/noticeDetail",
        query: {
          id: id,
          type: type,
        },
      });
    },
    getNotice(type, xdType) {
      getNoticeList({
        nType: type,
        xdType: xdType,
        schoolCode: this.schoolCode,
        xswz: 1,
        page: 1,
        size: 5,
      }).then((res) => {
        if (type == 1) {
          if (xdType == 1) {
            this.smallNoticeList = res.data.rows;
          } else if (xdType == 2) {
            this.middleNoticeList = res.data.rows;
          }
        } else if (type == 2) {
          this.noticeList = res.data.rows;
        }
      });
    },
    toLogin(item) {
      if (item.enable == 1) {
        localStorage.setItem(`stage`, item.enterType);
        localStorage.setItem(`stageText`, item.enterTypeText);
        localStorage.setItem(`bmStageId`, item.id);
        this.$router.push({
          name: "Login",
        });
      } else {
        this.showTip = true;
        this.tipCon = item.stageBz;
      }
    },
    getConfig() {
      getFlag({
        paramKey: "systemName",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        this.sysName = res.data.paramValue;
        localStorage.setItem(`sysName`, this.sysName);
      });
      getFlag({
        paramKey: "logo",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        this.sysLogo = res.data.paramValue;
        localStorage.setItem(`sysLogo`, this.sysLogo);
      });
      getFlag({
        paramKey: "systemArea",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        console.log(res, "222");
        localStorage.setItem(`systemArea`, res.data.paramValue);
      });
      getFlag({
        paramKey: "systemAreaCode",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        localStorage.setItem(`systemAreaCode`, res.data.paramValue);
      });
    },
  },
  mounted() {
    localStorage.setItem(`schoolCode`, "1111111111");
    localStorage.removeItem("user");
    this.getEnter();
    this.getConfig(); // 获取系统名称 logo
    // 1 报名须知  2相关政策 3通知公告
    // 学段类型  xdType 1 小学 2初中  0全部
    this.getNotice(1, 1);
    this.getNotice(1, 2);
    this.getNotice(2, 0);
  },
};
</script>

<style lang="less" scoped>
.page {
  background: url(~@/assets/enterBg.png) no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
}
.wrap {
  width: 1084px;
  margin: 0 auto;
  padding: 0px 0 0px;
  position: relative;
  .tip {
    width: 1084px;
    height: 40px;
    line-height: 40px;
    background: #fff1f0;
    border: 1px solid #ffa39e;
    border-radius: 2px;
    color: #ff3639;
    font-size: 14px;
    padding: 0 16px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    .icon {
      color: #ff4d4f;
      padding-right: 6px;
      font-size: 16px;
    }
  }
  .enter {
    display: flex;
    justify-content: space-between;
  }
  .card {
    width: 340px;
    margin-top: 28px;
    // background: #fff;
    // border-radius: 10px;
    // opacity: 0.85;
    .btn {
      width: 296px;
      height: 44px;
      margin: 22px auto 0;
      line-height: 44px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      background-color: #35ab61;
      box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
      border-radius: 24px;
      cursor: pointer;
    }
  }
  .title {
    display: flex;
    align-items: center;
    .logo {
      width: 34px;
      height: 34px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.27);
    }
    .name {
      color: #fff;
      font-size: 32px;
      font-weight: 600;
      margin-left: 12px;
    }
  }
  .head {
    color: #fff;
    text-align: center;
    padding: 12px 16px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .stage {
      font-size: 24px;
      margin-bottom: 6px;
      font-weight: 600;
    }
    .time {
      font-size: 12px;
    }
  }
  .process {
    text-align: center;
    padding-top: 14px;
    text-decoration: underline;
  }
  .notice {
    background: #fff;
    opacity: 0.85;
    padding: 20px 24px 32px 18px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .type {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #333;
      font-weight: 600;
      margin-bottom: 16px;
      .more {
        font-size: 14px;
        color: #666;
        font-weight: normal;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .list {
      min-height: 175px;
      .item {
        display: flex;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover {
          .tit {
            color: #35ab61;
          }
        }
        .dot {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #35ab61;
          margin: 8px;
        }
        .tit {
          width: 314px;
          font-size: 14px;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .small {
    .head {
      background-image: linear-gradient(139deg, #58be6d 0%, #68d486 100%);
    }
    .process {
      a {
        color: #35ab61;
      }
    }
  }
  .middle {
    .head {
      background-image: linear-gradient(136deg, #ff9d00 0%, #ffc900 100%);
    }
    .dot {
      background: #ffa000 !important;
    }
    .process {
      a {
        color: #ffa000;
      }
    }
    .notice {
      .list {
        .item {
          &:hover {
            .tit {
              color: #ffa000 !important;
            }
          }
        }
      }
    }
    .btn {
      background-color: #ffa000;
      box-shadow: 0px 7px 12px 0px rgba(255, 160, 0, 0.23);
    }
  }
  .ewm {
    .head {
      background-image: linear-gradient(136deg, #0091ff 0%, #4db2ff 100%);
    }
    .info {
      background: #fff;
      opacity: 0.85;
      padding: 20px 24px 26px 18px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .type {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #333;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .ewmBg {
      width: 238px;
      height: 240px;
      // width: 146px;
      // height: 148px;
      margin: 0 auto;
      background: url(~@/assets/ewmBg.png);
      background-size: 100%;
      text-align: center;
      line-height: 240px;
      // line-height: 148px;
      img {
        width: 228px;
        height: 228px;
      }
    }
    .desc {
      color: #333;
      font-size: 16px;
      margin-top: 18px;
      text-align: center;
    }
  }
}
.news {
  width: 800px;
  height: 104px;
  margin: 24px auto 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  color: #fff;
  padding: 14px 30px;
  .type {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    .more {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      font-weight: normal;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .title {
    color: #fff;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  /deep/ .ant-carousel .slick-dots-bottom {
    bottom: -8px;
  }
  /deep/ .ant-carousel .slick-dots {
    li {
      margin: 0 4px;
    }
    li button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
}
.resultWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    font-size: 48px;
    color: #35ab61;
    margin-bottom: 20px;
  }
  .title {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .desc {
    font-size: 14px;
    color: #666;
  }
  .result-btn {
    width: 280px;
    margin-top: 36px;
    margin-bottom: 16px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
  .cancel-btn {
    width: 280px;
    border-color: #35ab61;
    background: #fff;
    color: #35ab61;
    box-shadow: none;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}
</style>
